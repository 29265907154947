<template>
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-current">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 12a4 4 0 11-8 0 4 4 0 018 0zm-2 0c0-1.08-.92-2-2-2s-2 .92-2 2 .92 2 2 2 2-.92 2-2z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.85 17.87l-1-1.73a2 2 0 01.73-2.74l.5-.29a7.67 7.67 0 010-2.22l-.5-.3a2 2 0 01-.73-2.73l1-1.73a2.06 2.06 0 012.73-.73l.52.3A8.13 8.13 0 019 4.6V4c0-1.1.9-2 2-2h2a2 2 0 012 2v.6a8.1 8.1 0 011.9 1.11l.53-.3c.92-.54 2.2-.2 2.73.73l1 1.73a2 2 0 01-.74 2.73l-.5.29a7.66 7.66 0 010 2.22l.5.3a2 2 0 01.73 2.73l-1 1.73a2.06 2.06 0 01-2.72.73l-.53-.3c-.59.45-1.22.82-1.9 1.1v.6a2 2 0 01-2 2h-2a2 2 0 01-2-2v-.6a8.1 8.1 0 01-1.9-1.11l-.52.3c-.93.54-2.2.2-2.73-.72zM6 12c0 .46.06.93.17 1.38a1 1 0 01-.47 1.1l-1.12.66 1 1.73 1.14-.67a1 1 0 011.19.14c.68.65 1.5 1.14 2.38 1.4a1 1 0 01.71.96V20h2v-1.3a1 1 0 01.7-.96c.89-.26 1.7-.75 2.4-1.4a1 1 0 011.18-.14l1.14.66 1-1.72-1.12-.65a1 1 0 01-.47-1.11 5.7 5.7 0 000-2.76 1 1 0 01.47-1.1l1.12-.65-1-1.73-1.14.66a1 1 0 01-1.19-.14 6.08 6.08 0 00-2.38-1.4A1 1 0 0113 5.3V4h-2v1.3a1 1 0 01-.7.96c-.89.26-1.7.75-2.4 1.4a1 1 0 01-1.18.14l-1.14-.67-1 1.73 1.12.65a1 1 0 01.47 1.11c-.11.45-.17.92-.17 1.38z"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
