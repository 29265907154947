<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 19.411C16.411 19.411 20 15.822 20 11.411C20 8.06101 17.928 5.19002 15 4.00002V6.22301C16.79 7.26301 18 9.19601 18 11.411C18 14.72 15.309 17.411 12 17.411C8.691 17.411 6 14.72 6 11.411C6 9.19601 7.21 7.26201 9 6.22301V4.00002C6.072 5.19002 4 8.06101 4 11.411C4 15.822 7.589 19.411 12 19.411Z"
      fill="#FF7272"
    />
    <path d="M11 2.00002H13V12H11V2.00002Z" fill="#FF7272" />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
