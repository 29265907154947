<template>
  <svg viewBox="0 0 20 18" class="fill-current" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11 7H9v5h2V7zm0 6H9v2h2v-2z" fill="#FF7272" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 .1a2 2 0 011.8 1.1L19 15.1a2 2 0 010 2c-.4.5-1 .9-1.8.9H2.7a2 2 0 01-1.8-1 2 2 0 010-2L8.2 1.3c.4-.7 1-1 1.8-1zm0 2L2.7 16h14.6L10 2.1z"
    />
  </svg>
</template>

<script>
export default {}
</script>
