<template>
  <div class="flex flex-col h-full">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'SmartHub',
}
</script>
