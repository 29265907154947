<template>
  <svg
    width="225"
    height="160"
    viewBox="0 0 225 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M92.56 158.19c38.43 0 69.58-30.82 69.58-68.84s-31.15-68.84-69.58-68.84C54.14 20.51 23 51.33 23 89.35s31.15 68.84 69.57 68.84z"
      fill="#F2F2F2"
    />
    <path
      d="M186.09 38.66l-7.26 8.52c-.4 3.66-.95 7.76-1.74 12.06l11.93-12.2.05.2a32.9 32.9 0 00-3.05-8.78l.06.2zM178.9 46.53l7.03-8.24a17.51 17.51 0 00-6.17-6.78c0 1-.03 6.88-.87 15.02zM187.9 72.93l.02.25c.4-1.52.75-3.03 1.04-4.55l-17.3 11.05v-.11c-.33.88-.67 1.74-1.01 2.59l17.25-9.23zM189.96 59.5l.04.24c.2-4.1-.1-8.22-.88-12.26l-12.15 12.44c-.76 4.02-1.65 7.85-2.68 11.5l15.67-11.91zM159.3 38.7a43.42 43.42 0 00-4.64 7.46l20.03 22.62c.82-3.17 1.49-6.25 2.02-9.12L159.2 38.92l.1-.23zM153.24 49.27l.1-.23 20.57 22.58c.23-.77.44-1.54.64-2.3l-20.1-22.71.1-.23c-.46.93-.89 1.89-1.3 2.89zM188.99 68.1l.02.24c.52-2.75.84-5.54.98-8.33L174.1 72.08c-.7 2.42-1.46 4.74-2.27 6.97l17.15-10.95zM166.07 91.57a50.79 50.79 0 01-9.12 11.7c3.74-1.49 18.79-8.05 26.59-19.9l-17.47 8.2zM150.12 60.76c-.3 2.67-.42 5.36-.36 8.05l.13-.25 16.13 22.4c1.48-2.61 2.8-5.3 3.96-8.07l-19.86-22.13zM178.6 46.5c.9-8.8.85-14.93.85-15.02l.16-.06c-3.64-2.09-7.65-2.01-11.56-.16L178.6 46.5zM170.37 82.82l-.32.78a74.16 74.16 0 01-3.58 7.27l17.3-8.12V83a29.31 29.31 0 003.23-6.72c.3-.93.58-1.87.84-2.8l-17.47 9.34zM176.82 59.09c.77-4.25 1.32-8.3 1.7-11.93l-10.78-15.6.11-.21c-2.93 1.44-5.8 3.88-8.4 7.16l17.37 20.58z"
      fill="#6CAEFC"
    />
    <path
      d="M165.78 91.37l-16-22.23c.38 16.11 5.94 33.3 6.3 34.4 3.84-3.4 7.04-7.6 9.7-12.17zM170.17 82.42l.01-.01c1.38-3.36 2.58-6.79 3.59-10.28L153.15 49.5a59.63 59.63 0 00-1.56 4.22 35.9 35.9 0 00-1.4 6.44l19.98 22.26zM202.4 104.81l-11.08 1.98a166.35 166.35 0 01-9.11 8.2l17.05-1.88-.09.2c1.6-2.95 2.72-5.9 3.31-8.7l-.08.2zM191.79 106.34l10.73-1.92a17.27 17.27 0 00-.38-9.11c-.64.77-4.46 5.29-10.35 11.03zM181.71 132.35l-.15.2c1.3-.9 2.54-1.86 3.74-2.84l-20.44-2.4.06-.1c-.82.47-1.64.92-2.45 1.36l19.24 3.78zM191.95 123.31l-.12.2c2.8-3.03 5.22-6.4 7.23-10l-17.38 1.92a135.34 135.34 0 01-9.47 7.16l19.74.72zM181.75 87.93c-2.88.66-5.7 1.6-8.38 2.82l.84 30.07c2.68-1.92 5.17-3.88 7.44-5.75l-.12-27.03.22-.1zM170.27 92.25l.23-.1 1.28 30.36c.67-.45 1.33-.91 1.98-1.37l-.85-30.18.23-.1c-.95.42-1.9.89-2.87 1.4zM185.66 129.31l-.13.2a62.7 62.7 0 006.12-5.8l-20.01-.72c-2.1 1.42-4.18 2.74-6.25 3.94l20.28 2.38zM152.88 132.93a51.72 51.72 0 01-14.57 3.26c3.84 1.2 19.67 5.65 33.3 1.45l-18.73-4.71z"
      fill="#6CAEFC"
    />
    <path
      d="M160.45 99.13a60.45 60.45 0 00-5.45 5.98l.25-.1-2.01 27.42a75.94 75.94 0 008.25-3.72l-1.04-29.58zM191.58 106.13a152.1 152.1 0 0010.35-11.04l.15.05c-1.45-3.9-4.59-6.37-8.8-7.41l-1.7 18.4zM161.84 128.9l-.76.4a75.55 75.55 0 01-7.44 3.34l18.56 4.67-.15.2a29.9 29.9 0 006.8-3.15c.84-.53 1.66-1.07 2.46-1.63l-19.47-3.83zM182.1 114.7c3.34-2.79 6.37-5.56 9-8.1l1.75-18.83.23-.1c-3.2-.74-6.98-.67-11.1.21l.12 26.82zM152.8 132.6l1.99-27.23c-10.1 12.65-16.9 29.4-17.32 30.47a50.1 50.1 0 0015.32-3.24zM161.94 128.47h.02c3.22-1.72 6.35-3.6 9.38-5.65l-1.3-30.45c-1.3.7-2.6 1.45-3.9 2.27a36.53 36.53 0 00-5.24 4.07l1.04 29.76zM144.86 68.16l-4.24 10.32c.77 3.6 1.52 7.67 2.12 12l7.52-15.26.13.19a33.14 33.14 0 00-5.66-7.42l.13.17zM140.48 77.84l4.11-10a17.68 17.68 0 00-7.98-4.55c.31.95 2.13 6.56 3.87 14.55zM157.3 100.2l.08.22a62.2 62.2 0 00-.42-4.64l-13 15.8-.05-.1c-.03.93-.08 1.86-.14 2.77l13.52-14.06zM155.06 86.8l.1.2a51.78 51.78 0 00-4.66-11.39l-7.67 15.55c.54 4.06.89 7.98 1.05 11.75l11.18-16.11zM119.4 76.38a43.1 43.1 0 00-2.09 8.52l26.12 15.39c-.2-3.27-.53-6.4-.92-9.3l-23.14-14.37.03-.24zM116.94 88.29l.03-.25 26.61 15.18-.11-2.39-26.22-15.44.03-.25a53.66 53.66 0 00-.34 3.15zM156.82 95.26l.1.23a61.56 61.56 0 00-1.68-8.22L143.9 103.6c.09 2.52.1 4.96.02 7.33l12.89-15.67z"
      fill="#6CAEFC"
    />
    <path
      d="M142.35 124.6a50.34 50.34 0 01-5.01 13.91c3.09-2.55 15.35-13.4 19.06-27.05l-14.05 13.13zM117.56 100.17a59.54 59.54 0 002.18 7.76l.04-.26 22.34 16.36c.6-2.94 1-5.9 1.24-8.88l-25.8-14.98zM140.19 77.91a149.62 149.62 0 00-3.88-14.56l.13-.1c-4.1-.87-7.9.42-11.04 3.38l14.79 11.28zM143.71 114.95l-.06.85c-.23 2.69-.61 5.36-1.14 8.01l13.92-13.01.09.23c.63-2.41.95-4.89.96-7.38 0-.98-.03-1.95-.08-2.92l-13.69 14.22zM142.43 90.42c-.6-4.28-1.34-8.3-2.1-11.87L125.2 67.02l.04-.24c-2.35 2.26-4.32 5.47-5.77 9.38l22.96 14.26z"
      fill="#6CAEFC"
    />
    <path
      d="M142.02 124.5l-22.17-16.25c5.4 15.2 16.06 29.86 16.74 30.79a48.78 48.78 0 005.43-14.55zM143.4 114.64v-.02c.27-3.61.33-7.24.2-10.86l-26.68-15.22c-.1 1.45-.16 2.95-.16 4.49.02 2.2.25 4.39.67 6.55l25.96 15.06z"
      fill="#6CAEFC"
    />
    <path
      d="M112.5 160c62.13 0 112.5-2.88 112.5-6.44 0-3.56-50.37-6.44-112.5-6.44S0 150 0 153.56c0 3.56 50.37 6.44 112.5 6.44z"
      fill="#3F3D56"
    />
    <path
      opacity=".1"
      d="M151.56 145.35c0 12.52-17.64 8.65-39.23 8.65-21.6 0-38.97 3.87-38.97-8.65 0-12.51 17.64-15.24 39.23-15.24 21.6 0 38.97 2.72 38.97 15.24z"
      fill="#000"
    />
    <path
      d="M151.56 142.27c0 12.52-17.64 8.65-39.23 8.65-21.6 0-38.97 3.87-38.97-8.65S91 127.03 112.6 127.03c21.6 0 38.97 2.72 38.97 15.24z"
      fill="#3F3D56"
    />
    <path
      d="M155.83 90.1s0 21 1.13 25.66c1.14 4.65 5.19 21.49 5.19 21.49l5.51-1.12s-.97-22.3-1.94-25.5c-.98-3.21 1.13-16.37 3.89-19.25 2.75-2.9-1.3-4.66-1.3-4.66l-12.48 3.37z"
      fill="#2F2E41"
    />
    <path
      opacity=".1"
      d="M155.83 90.1s0 21 1.13 25.66c1.14 4.65 5.19 21.49 5.19 21.49l5.51-1.12s-.97-22.3-1.94-25.5c-.98-3.21 1.13-16.37 3.89-19.25 2.75-2.9-1.3-4.66-1.3-4.66l-12.48 3.37z"
      fill="#000"
    />
    <path
      d="M169.12 133.24s-7.3 2.89-7.78 2.89c-.49 0-.81 8.34-.81 8.34s.48 4.97-7.46 6.57c-7.94 1.6 6.32 5.14 8.92 3.37a21.5 21.5 0 003.72-3.05l.98-1.6-.33 2.73 1.14.16 2.6-5.14s.64-3.85-.17-4.8c-.81-.97-.81-9.47-.81-9.47z"
      fill="#2F2E41"
    />
    <path d="M132.48 103.08l1.14 6.26 5.35-.48-.98-4.5-5.5-1.28z" fill="#A0616A" />
    <path
      d="M158.74 76.62l-.97 1.6s-28.04-3.85-28.04 6.42c0 10.26-1.3 16.36-1.63 16.84-.32.48 8.44 5.61 10.38 5.3 0 0 1.3-1.77 1.14-4.02-.17-2.24 1.3-10.58 0-11.06 0 0 1.62-.64 1.45-1.6 0 0 22.86 3.2 25.78 2.56 2.92-.64 4.86-2.73 5.35-4.01.49-1.28 1.53-6.29 1.53-6.29l-.23-8.47-2.6-3.04-3.89-.49-6 3.7-2.27 2.56z"
      fill="#2F2E41"
    />
    <path d="M176.09 91.86s-2.27 5.13.32 5.13c2.6 0 3.57-4.5 3.57-4.5l-3.89-.63z" fill="#A0616A" />
    <path
      d="M158.74 50.32l-.8-.97s-9.08-20.2-5.52-25.66c3.57-5.45 19.13-6.26 19.62-5.93.48.32 1.94 1.44 1.78 1.76-.16.32-3.4 1.12-2.27 3.05 0 0-1.62.48-1.94-.16-.33-.64-1.3-.8-1.63-.16-.32.64-5.5 2.08-5.5 2.56s-.82 2.89-1.63 3.21c-.8.32.16 5.13 1.78 6.42 1.63 1.28 2.76 4.8 2.76 4.8l-.81 7.23-5.84 3.85z"
      fill="#D3E3F1"
    />
    <path
      d="M163.38 23.33a5.83 5.83 0 00-2.51 2.1c-1.2 2-.44 4.75-1.18 7.02-.31.96-.9 1.8-1.37 2.67a5 5 0 00-.73 2.91c.12 1 .71 1.84.99 2.8.48 1.65 0 3.44-.55 5.06-.3.89-.61 1.77-.95 2.65-.2.5-.43 1.04-.87 1.29l3.83.31c1.12.1 2.35.14 3.23-.62.35-.34.64-.74.84-1.19.86-1.79 1-3.9.9-5.92-.1-2.03-.42-4.04-.4-6.07-.02-.66.07-1.33.27-1.97.2-.5.45-.98.72-1.44.88-1.67 1.24-3.6 1.59-5.48.23-1.3.62-3.17-.07-4.39-.68-1.21-2.81-.2-3.74.27z"
      fill="#2F2E41"
    />
    <path
      d="M167.17 37.48a6.61 6.61 0 006.65-6.57 6.61 6.61 0 00-6.65-6.58 6.61 6.61 0 00-6.64 6.58 6.61 6.61 0 006.64 6.57z"
      fill="#A0616A"
    />
    <path
      d="M164.58 36.52s1.05 4.1.4 6.18c-.64 2.08 8.84-2.81 8.84-2.81s-2.92-3.85-2.92-5.13l-6.32 1.76z"
      fill="#A0616A"
    />
    <path
      d="M176.74 42.78s-2.1-1.6-2.1-2.4c0-.81-1.47-2.58-1.47-2.58s-6.97 3.53-7.3 3.53c-.32 0-.48-2.08-.48-2.08l-7.46 10.1s-2.43 5.78-1.62 10.27c.81 4.49 2.11 9.14 1.46 13.31-.65 4.17.16 5.61 1.14 5.13.97-.48 0-2.08 2.1-1.92 2.11.16 9.24-2.09 9.73-3.05.49-.96 2.43 2.09 2.6 3.85.16 1.76-.33 6.58 0 6.42.32-.16.8-2.9.97-4.17.16-1.29.65-5.46 0-6.1-.65-.64-.16-1.6.32-1.92.49-.32 8.43-20.21 8.43-20.21s-.8-4.17-3.56-5.62c-2.76-1.44-2.76-2.56-2.76-2.56z"
      fill="#D3E3F1"
    />
    <path
      d="M180.47 48.23l2.6 2.73v14.43s3.4 17.32 1.29 18.29c-2.1.96-3.08.64-3.08.64s.48 8.82-.97 8.82c-1.46 0-3.73-.64-5.03-.32 0 0 .32-3.2 1.3-4.65.97-1.45.97-4.01.97-4.01s-1.46 0-1.46-2.09c0-2.08-.16-3.37-.32-3.85-.17-.48-1.3-8.98-1.3-8.98l6-21.01z"
      fill="#D3E3F1"
    />
    <path
      d="M165.75 23c1.13-.71 2.62-.18 3.92-.44 1.42-.28 2.6-1.5 4.04-1.43 1.2.05 2.15 1 2.93 1.9.72.8 1.35 1.69 1.86 2.64.53 1.06.82 2.22 1.1 3.36l.97 3.85c.32 1.3.65 2.62 1.24 3.83a6.54 6.54 0 002.66 2.96 7.91 7.91 0 01-4.47.02 9.09 9.09 0 002.7 3.73c-1.09.32-2.25 0-3.38.07a4.82 4.82 0 00-3.88 2.51c-.6 1.14-.68 2.46-1.03 3.69a9.27 9.27 0 01-8.56 6.5c1.08-1.55 2.21-3.34 1.8-5.17-.26-1.1-1.06-2.03-1.3-3.14-.25-1.18.17-2.4.45-3.57.29-1.18.4-2.53-.33-3.5-.79-1.03-2.36-1.36-2.86-2.55a3.6 3.6 0 01-.2-1.36l-.03-2.11c-.03-.45 0-.89.1-1.32.18-.57.56-1.04.92-1.5a8 8 0 001.64-2.91c.33-1.32-.18-2.14-.83-3.2-.56-.93-.43-2.25.54-2.87z"
      fill="#2F2E41"
    />
    <path
      d="M172.85 18.88s7.46 6.41 5.51 8.66c-1.95 2.24-5.03-4.17-5.03-4.17l-3.24-2.4 2.76-2.1z"
      fill="#A0616A"
    />
    <path
      d="M141.72 105s-8.59 2.1-9.56 3.86c0 0-1.14 5.45-1.14 7.7 0 2.24-.48 6.41-5.02 7.22-4.54.8-7.78 2.24-1.62 3.36 6.16 1.13 7.94 1.45 9.72-.96 1.79-2.4 2.76-3.05 2.76-3.05l1.3 3.21h.8l3.09-6.57s.32-3.21-.49-5.14c-.81-1.92.16-9.62.16-9.62z"
      fill="#2F2E41"
    />
    <path d="M173.74 19.44s-.25 2.57-3.77 3.05l-.77-2.4 2.43-2.1 2.11 1.45z" fill="#D0CDE1" />
    <path
      d="M87.17 118.07a17.4 17.4 0 0017.5-17.3 17.4 17.4 0 00-17.5-17.32 17.4 17.4 0 00-17.5 17.31 17.4 17.4 0 0017.5 17.31zM78.27 75.05a13.85 13.85 0 01-9.88-.44 13.65 13.65 0 01-7-6.9L42.41 25.57a18 18 0 014.39-20.8 18.55 18.55 0 0121.4-2.41 18.3 18.3 0 018.9 11.85L87.3 59.22c.74 3.28.23 6.71-1.44 9.63a13.68 13.68 0 01-7.59 6.2z"
      fill="#6CAEFC"
    />
    <path
      opacity=".1"
      d="M87.17 118.07a17.4 17.4 0 0017.5-17.3 17.4 17.4 0 00-17.5-17.32 17.4 17.4 0 00-17.5 17.31 17.4 17.4 0 0017.5 17.31zM78.27 75.05a13.85 13.85 0 01-9.88-.44 13.65 13.65 0 01-7-6.9L42.41 25.57a18 18 0 014.39-20.8 18.55 18.55 0 0121.4-2.41 18.3 18.3 0 018.9 11.85L87.3 59.22c.74 3.28.23 6.71-1.44 9.63a13.68 13.68 0 01-7.59 6.2z"
      fill="#000"
    />
    <path
      d="M86.53 112.74c7.75 0 14.03-6.22 14.03-13.89 0-7.67-6.28-13.89-14.03-13.89a13.96 13.96 0 00-14.04 13.9c0 7.66 6.28 13.88 14.04 13.88zM76.6 70.09c-2.96.98-6.2.84-9.06-.4a12.52 12.52 0 01-6.43-6.34L43.7 24.68A16.52 16.52 0 0147.74 5.6a17.02 17.02 0 0124.9 2.37 16.6 16.6 0 012.9 6.3l9.35 41.3c.68 3 .2 6.16-1.32 8.84-1.53 2.68-4.01 4.7-6.96 5.69z"
      fill="#6CAEFC"
    />
  </svg>
</template>

<script>
export default {}
</script>
