<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.2 6.3l-4.3 4.3-4.2-4.3-1.4 1.5 4.2 4.2-4.2 4.2 1.4 1.5 4.2-4.3 4.3 4.3 1.4-1.5-4.2-4.2 4.2-4.2-1.4-1.5z"
      fill="#868686"
    />
  </svg>
</template>

<script>
export default {}
</script>
