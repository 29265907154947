<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 5.5C13 3.57 11.43 2 9.5 2C7.466 2 6.25 3.525 6.25 5H8.25C8.25 4.585 8.638 4 9.5 4C10.327 4 11 4.673 11 5.5C11 6.327 10.327 7 9.5 7H2V9H9.5C11.43 9 13 7.43 13 5.5ZM15.5 15H8V17H15.5C16.327 17 17 17.673 17 18.5C17 19.327 16.327 20 15.5 20C14.638 20 14.25 19.415 14.25 19H12.25C12.25 20.475 13.466 22 15.5 22C17.43 22 19 20.43 19 18.5C19 16.57 17.43 15 15.5 15Z"
      fill="#6CAEFC"
    />
    <path
      d="M18 5C15.794 5 14 6.794 14 9H16C16 7.897 16.897 7 18 7C19.103 7 20 7.897 20 9C20 10.103 19.103 11 18 11H2V13H18C20.206 13 22 11.206 22 9C22 6.794 20.206 5 18 5ZM2 15H6V17H2V15Z"
      fill="#6CAEFC"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
