<template>
  <svg width="23" height="23" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 22h2v14a4 4 0 004 4h24a4 4 0 004-4V22h2a2 2 0 001.4-3.4l-18-18a2 2 0 00-2.8 0l-18 18A2 2 0 002 22zm14 14V26h8v10h-8zm16-19.2l-12-12-12 12V36h4V26a4 4 0 014-4h8a4 4 0 014 4v10h4V16.8z"
      fill="#FFDB5A"
    />
  </svg>
</template>

<script>
export default {}
</script>
