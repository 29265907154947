const _temp0 = require("./Close.vue");
const _temp1 = require("./Cog.vue");
const _temp2 = require("./Home.vue");
const _temp3 = require("./PowerOff.vue");
const _temp4 = require("./Sun.vue");
const _temp5 = require("./Warn.vue");
const _temp6 = require("./WifiOff.vue");
const _temp7 = require("./Wind.vue");
module.exports = {
  "Close": _temp0,
  "Cog": _temp1,
  "Home": _temp2,
  "PowerOff": _temp3,
  "Sun": _temp4,
  "Warn": _temp5,
  "WifiOff": _temp6,
  "Wind": _temp7
}