<template>
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.948 10.12L7.414 4.586 4.586 7.414l36 36
    2.828-2.828L33.39 30.562l1.952-1.95c-2.29-2.29-5.17-3.716-8.198-4.296l-4.556-4.556c5.578-.382
    11.298 1.496 15.458 5.654l2.828-2.828c-5.796-5.798-14.122-7.872-21.776-6.316l-3.05-3.05A26.731
    26.731 0 0124 12c7.074 0 13.674 2.706 18.586 7.618l2.828-2.828C39.748 11.122 32.142 8 24
    8c-3.868.002-7.582.768-11.052 2.12zM2.586 16.79l2.828 2.828c1.008-1.008 2.104-1.9
    3.244-2.718L5.8 14.042c-1.12.844-2.208 1.74-3.214 2.748zm7.368 8.624l-2.828-2.828a23.034 23.034
    0 014.026-3.192l2.918 2.918a19.127 19.127 0 00-4.116 3.102zm2.704 3.2l2.828 2.828c1.384-1.384
    3.07-2.302 4.858-2.856l-3.114-3.114a15.518 15.518 0 00-4.572 3.142zm11.12 3.406l4.2 4.2C27.862
    38.324 26.132 40 24 40a3.999 3.999 0 01-4-4c0-2.134 1.676-3.862 3.778-3.98z"
      class="fill-current"
    />
  </svg>
</template>

<script>
export default {}
</script>
